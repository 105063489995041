<template>
  <a-modal
    v-model:visible="visible"
    title="添加回复"
    @ok="handleSubmit"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
        <a-form-item label="类型" name="rule_type" >
            <a-radio-group 
            v-model:value="formState.rule_type">
            <a-radio value="KEYWORDS">
                关键词
            </a-radio>
            <a-radio value="AFTER">
                关注后
            </a-radio>
            </a-radio-group>
      </a-form-item>
      <a-form-item has-feedback label="标题" name="rule_name">
        <a-input
          v-model:value="formState.rule_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="关键词" name="keywords_name">
        <a-input
          v-model:value="formState.keywords_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item label="回复">
        <a-textarea v-model:value="formState.reply_content" placeholder="" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRaw } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/addons/client/wechatApi";
export default defineComponent({
  name: "create-reply-rule",
  props: [],
  setup(props, context) {
    console.log(props);
    // 显示弹框
    const visible = ref(false);

    // 打开弹框
    const showFromModal = () => {
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      rule_type: "KEYWORDS",
      rule_name: "",
      keywords_name: "",
      reply_content: "",
    });

    const rules = {
      rule_name: [
        {
          required: true,
          min: 2,
          message: "请输入标题",
          trigger: "change",
        },
      ],
      reply_content: [
        {
          required: true,
          min: 0,
          message: "请输入回复词",
          trigger: "change",
        },
      ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log("values", formState, toRaw(formState));
          Api.wechat_reply_create(formState).then(
            (res) => {
                if(res.status==200){
                    message.success(res.message);
                }else{
                    message.error(res.message);
                }
              
              visible.value = false;
              // 通知父组件刷新列表
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmit,
    };
  },
});
</script>