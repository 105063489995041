<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateReplyRuleModal()">新的回复</a-button>
    </div>
    <a-table class="doc-table" rowKey="id" :dataSource="dataSource" :columns="columns" :pagination="Refpagination" @change="onLoadData">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <a-button type="primary" class="margin-right15" size="small" @click="showUpdateReplyRuleModal(record)">编辑
          </a-button>
          <a-button type="primary" danger size="small" @click="showDeleteConfirm(record)">删除</a-button>
        </template>
        <!-- <template v-if="column.key === 'keywords_json'">
          {{record.keywords_arr.keywords_name}}
        </template> -->
        <template v-if="column.key === 'replay_json'">
          {{record.replay_arr.reply_content}}
        </template>
      </template>
    </a-table>
    <create-reply-rule ref="RefCreateReplyRule" @handleSubmit="onLoadData" />
    <update-reply-rule ref="RefUpdateReplyRule" @handleSubmit="onLoadData" />
  </div>
</template>
<script>
  import {
    defineComponent,
    ref,
    reactive,
    onMounted
  } from "vue";
  import * as Api from "@/addons/client/wechatApi";
  import CreateReplyRule from "./modules/replay/CreateReplyRule.vue";
  import UpdateReplyRule from "./modules/replay/UpdateReplyRule.vue";
  import { Modal, message } from "ant-design-vue";
  export default defineComponent({
    components: {
      CreateReplyRule,
      UpdateReplyRule,
    },
    setup() {

      // 添加回复
      const RefCreateReplyRule = ref();
      const showCreateReplyRuleModal = () => {
        RefCreateReplyRule.value.showFromModal();
      };

      // 编辑回复
      const RefUpdateReplyRule = ref();
      const showUpdateReplyRuleModal = (item) => {
        RefUpdateReplyRule.value.showFromModal(item);
      };
     

      const formState = reactive({
      });
      const dataSource = ref();
      const Refpagination = ref({});
      const onLoadData = (pagination) => {
        if(typeof pagination !=='undefined'){
          formState.page = pagination.current
        }
        Api.wechat_list_reply(formState).then((res) => {
          Refpagination.value.current = res.data.reply_list.current_page;
          Refpagination.value.pageSize = res.data.reply_list.per_page;
          Refpagination.value.total = res.data.reply_list.total;
          dataSource.value = res.data.reply_list.data;
        });
      };

      const showDeleteConfirm = (record) => {
        Modal.confirm({
          title: "确定要删除【" + record.rule_name + "】吗?",
          // icon: createVNode(ExclamationCircleOutlined),
          content: "删除后将无法恢复，请谨慎操作！",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            Api.wechat_reply_del({ id: record.rule_id }).then((res) => {
              message.success(res.message);
              onLoadData();
            });
          },
          onCancel() {
            // console.log("Cancel");
          },
        });
    };
      // 初始化获取左侧导航
      onMounted(() => {
        onLoadData();
      });

      return {
        RefCreateReplyRule,
        RefUpdateReplyRule,
        showCreateReplyRuleModal,
        showUpdateReplyRuleModal,
        showDeleteConfirm,
        dataSource,
        Refpagination,
        onLoadData,
        columns: [{
            title: "id",
            dataIndex: "rule_id",
            key: "rule_id",
            width: "10%",
          },
          {
            title: "类型",
            dataIndex: "rule_type",
            key: "rule_type",
            width: "10%",
          },
          {
            title: "标题",
            dataIndex: "rule_name",
            key: "rule_name",
            width: "10%",
          },
          {
            title: "关键词",
            dataIndex: "keywords_name",
            width: "10%",
          },
          {
            title: "回复",
            key: "replay_json",
            width: "10%",
          },
          {
            title: "更新时间",
            dataIndex: "update_time",
            key: "update_time",
            width: "20%",
          },
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            width: "10%",
          },
        ],
      };
    },
  });
</script>